const designations = [
    // Business and Corporate
    "Chief Executive Officer (CEO)",
    "Managing Director (COO)",
    "Chief Financial Officer (CFO)",
    "Chief Technology Officer (CTO)",
    "Chief Marketing Officer (CMO)",
    "Chief Human Resources Officer (CHRO)",
    "Chief Information Officer (CIO)",
    "President",
    "Vice President (VP)",
    "Director",
    "General Manager (GM)",
    "Manager",
    "Assistant Manager",
    "Supervisor",
    "Team Leader",
    "Senior Analyst",
    "Analyst",
    "Associate",
    "Coordinator",
    "Administrative Assistant",
    "Executive Assistant",
    "Intern",
  
    // Information Technology
    "Software Developer",
    "Senior Software Engineer",
    "Front-End Developer",
    "Back-End Developer",
    "Full Stack Developer",
    "Data Scientist",
    "Data Analyst",
    "Database Administrator",
    "IT Manager",
    "IT Support Specialist",
    "Network Administrator",
    "Systems Administrator",
    "DevOps Engineer",
    "Cybersecurity Analyst",
    "UX/UI Designer",
    "Product Manager",
    "Project Manager",
    "Quality Assurance (QA) Engineer",
    "Technical Writer",
    "Cloud Architect",
  
    // Marketing and Sales
    "Sales Manager",
    "Account Manager",
    "Business Development Manager",
    "Marketing Manager",
    "Marketing Coordinator",
    "Digital Marketing Specialist",
    "Social Media Manager",
    "Content Strategist",
    "Copywriter",
    "Brand Manager",
    "Public Relations (PR) Specialist",
    "SEO Specialist",
    "Market Research Analyst",
    "Sales Representative",
    "Advertising Manager",
    "Event Planner",
    "Product Marketing Manager",
    "Customer Relationship Manager (CRM)",
    "Lead Generation Specialist",
    "Retail Manager",
  
    // Finance and Accounting
    "Accountant",
    "Senior Accountant",
    "Controller",
    "Financial Analyst",
    "Senior Financial Analyst",
    "Auditor",
    "Tax Advisor",
    "Treasurer",
    "Budget Analyst",
    "Payroll Specialist",
    "Accounts Payable Clerk",
    "Accounts Receivable Clerk",
    "Financial Planner",
    "Investment Analyst",
    "Risk Manager",
    "Credit Analyst",
    "Loan Officer",
    "Mortgage Broker",
    "Bookkeeper",
    "Finance Manager",
  
    // Human Resources
    "HR Manager",
    "HR Generalist",
    "HR Specialist",
    "Recruiter",
    "Talent Acquisition Manager",
    "HR Coordinator",
    "HR Assistant",
    "Compensation and Benefits Manager",
    "Training and Development Manager",
    "Employee Relations Specialist",
    "HR Business Partner",
    "Labor Relations Specialist",
    "Organizational Development Manager",
    "Payroll Manager",
    "Diversity and Inclusion Specialist",
  
    // Healthcare
    "Physician",
    "Surgeon",
    "Nurse",
    "Registered Nurse (RN)",
    "Licensed Practical Nurse (LPN)",
    "Medical Assistant",
    "Nurse Practitioner",
    "Physician Assistant",
    "Pharmacist",
    "Physical Therapist",
    "Occupational Therapist",
    "Radiologist",
    "Medical Technologist",
    "Lab Technician",
    "Health Administrator",
    "Medical Billing Specialist",
    "Health Information Technician",
    "Clinical Research Coordinator",
    "Nutritionist",
    "Dentist",
    "Dental Hygienist",
    "Veterinary Technician",
  
    // Education
    "Teacher",
    "Professor",
    "Assistant Professor",
    "Associate Professor",
    "Lecturer",
    "Adjunct Professor",
    "Principal",
    "Vice Principal",
    "Dean",
    "Academic Advisor",
    "School Counselor",
    "Education Coordinator",
    "Curriculum Developer",
    "Instructional Designer",
    "Special Education Teacher",
    "Teaching Assistant",
    "Research Assistant",
    "Librarian",
    "Education Administrator",
    "Tutor",
  
    // Engineering
    "Mechanical Engineer",
    "Electrical Engineer",
    "Civil Engineer",
    "Structural Engineer",
    "Chemical Engineer",
    "Environmental Engineer",
    "Biomedical Engineer",
    "Industrial Engineer",
    "Aerospace Engineer",
    "Petroleum Engineer",
    "Software Engineer",
    "Hardware Engineer",
    "Systems Engineer",
    "Project Engineer",
    "Quality Engineer",
    "Process Engineer",
    "Design Engineer",
    "Manufacturing Engineer",
    "Maintenance Engineer",
    "Construction Manager",
  
    // Legal
    "Lawyer",
    "Attorney",
    "Paralegal",
    "Legal Assistant",
    "Legal Secretary",
    "Judge",
    "Magistrate",
    "Corporate Counsel",
    "General Counsel",
    "Legal Analyst",
    "Legal Consultant",
    "Legal Clerk",
    "Litigation Support Specialist",
    "Compliance Officer",
    "Contract Manager",
    "Intellectual Property (IP) Specialist",
    "Legal Researcher",
    "Mediator",
    "Arbitrator",
    "Court Reporter",
  
    // Media and Communications
    "Journalist",
    "Reporter",
    "Editor",
    "Copy Editor",
    "Content Writer",
    "Communications Manager",
    "Public Relations Manager",
    "Media Planner",
    "Broadcast Technician",
    "News Producer",
    "News Anchor",
    "Photographer",
    "Videographer",
    "Graphic Designer",
    "Art Director",
    "Creative Director",
    "Social Media Coordinator",
    "Technical Communicator",
    "Film Director",
    "Screenwriter",
  
    // Manufacturing and Production
    "Production Manager",
    "Operations Manager",
    "Quality Control Inspector",
    "Production Planner",
    "Manufacturing Engineer",
    "Assembly Line Worker",
    "Machinist",
    "CNC Operator",
    "Maintenance Technician",
    "Plant Manager",
    "Safety Coordinator",
    "Warehouse Manager",
    "Inventory Control Specialist",
    "Forklift Operator",
    "Supply Chain Manager",
    "Logistics Coordinator",
    "Procurement Specialist",
    "Materials Manager",
    "Packaging Engineer",
    "Production Supervisor",
  
    // Customer Service
    "Customer Service Representative",
    "Call Center Agent",
    "Customer Support Specialist",
    "Technical Support Specialist",
    "Customer Success Manager",
    "Client Relations Manager",
    "Help Desk Technician",
    "Service Manager",
    "Customer Experience Manager",
    "Customer Service Manager",
    "Retail Associate",
    "Sales Associate",
    "Front Desk Receptionist",
    "Account Coordinator",
    "Concierge",
    "Support Engineer",
    "Call Center Supervisor",
    "Complaint Resolution Specialist",
    "Client Services Manager",
    "Customer Retention Specialist"
  ];
  
  export default designations;
  